export const getMinShortNameOfUniversity = (universityName: string) => {
  const cleanName = universityName
    .replaceAll('"', '') // Remove all "
    .replaceAll("'", '') // Remove all '
    .replaceAll(/(\([^()]*\))/g, ''); // Remove everything between brackets and nested brackets

  // Check for special case 2: Find the first word with 2 or more uppercase letters
  const pattern = /((?:[А-ЯІЇЄ][а-яіїє]*[А-ЯІЇЄ]){1,}[А-ЯІЇЄа-яіїє]*)/;
  const match = cleanName.match(pattern);
  if (match) {
    return match[1];
  }

  return universityName;
};
