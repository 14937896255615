import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import styles from './StepHeader.module.scss';

export const StepHeader = ({
  step,
  closeStep,
  totalSteps = 3,
}: {
  step: string;
  closeStep: () => void;
  totalSteps?: number;
}) => {
  return (
    <div className={styles.wrap}>
      <div className={styles.arrowBack}>
        <ArrowIcon onClick={closeStep} direction={ArrowIconDirection.LEFT} />
      </div>
      <div className={styles.stepTitle}>
        <p>{`Крок ${step} з ${totalSteps}`}</p>
      </div>
    </div>
  );
};
