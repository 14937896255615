import { StepHeader } from '../Step/StepHeader';
import { Title } from '../Title/Title';
import styles from './ModalHeader.module.scss';

export const ModalHeader = ({ title, step, closeStep }: { title: string; step: string; closeStep: () => void }) => {
  return (
    <div className={styles.headerWrap}>
      <div className={styles.stepHeaderWrap}>
        <StepHeader closeStep={closeStep} step={step} />
      </div>
      <div className={styles.titleWrap}>
        <Title>{title}</Title>
      </div>
    </div>
  );
};
