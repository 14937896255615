import { ScoresQueryValue } from '../interfaces/filter-item.interface';

export const groupOfferFiltersRequest = ({
  offerPage,
  scoresQueryValues,
  universitiesQueryValues,
  filterParams,
  isZnoSort = false,
}: {
  offerPage: number;
  scoresQueryValues: { [key: string]: number };
  universitiesQueryValues: number[] | undefined;
  filterParams: {
    education_forms: string[] | undefined;
    sorting: string[] | undefined;
    regions: string[] | undefined;
    specialities: string[] | undefined;
    offer_types: string[] | undefined;
    education_bases: string[] | undefined;
  };
  isZnoSort: boolean | undefined;
}): any => {
  const znoScoresValue = Object.keys(scoresQueryValues).reduce((result, score) => {
    result[score] = scoresQueryValues[score];
    return result;
  }, {} as ScoresQueryValue);

  const filters = {
    page: offerPage,
    limit: 10,
    ...(!!universitiesQueryValues?.length && {
      universityIds: universitiesQueryValues,
    }),
    filters: {
      education_base_id: filterParams.education_bases?.map(Number),
      education_form_id: filterParams.education_forms?.map(Number),
      region_id: filterParams.regions?.map(Number),
      speciality_code: filterParams.specialities?.map(Number),
      ...(isZnoSort && { scores: znoScoresValue }),
    },
    ...(!isZnoSort && {
      sorting: {
        typeScore: 'mean',
        fieldScore: 'budget',
        type: filterParams.sorting?.includes(String(99)) ? 'desc' : 'asc',
      },
    }),
  };

  return filters;
};
