import { useMemo, useState } from 'react';

import { useOffersContext } from '../../layouts/OffersContext';
import { Subject, subjects } from '../../filters/subjects';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterItem } from '../../interfaces/filter-item.interface';
import { FilterTypes } from '../../types/filter.type';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { SortingCheckbox } from '../SortingCheckbox/SortingCheckbox';
import { SubTitle } from '../SubTitle/SubTitle';
import { ZnoScoresPopUp } from '../ZnoScoresPopUp/ZnoScoresPopUp';
import styles from './SortingFilter.module.scss';

export const SortingFilter = ({
  type,
  subTitle,
}: {
  type: FilterTypes;
  subTitle: string;
  handleFilterSearch?: () => void | null;
}) => {
  const { filters, handleChangingOfFilters, isSortingByZnoScoresChecked, znoScores, filterParams } = useOffersContext();
  const filter: FilterItem = filters[type];

  const [znoScoresPopupState, setZnoScoresPopupState] = useState({
    isSubjectModalOpen: false,
    isZnoScoresModalOpen: false,
    isAverageScoreModalOpen: false,
  });

  const scoreArray = useMemo(
    () =>
      Object.keys(znoScores.data).reduce((scoreArr: string[], idOfSubject) => {
        const subjectName = subjects.find((item: Subject) => item.id === +idOfSubject)?.subjectName;
        scoreArr.push(`${subjectName} - ${znoScores.data[+idOfSubject]}`);
        return scoreArr;
      }, []),
    [znoScores],
  );

  return (
    <div className={styles.wrap}>
      <FiltersBlock>
        <div className={styles.wrapBlock}>
          <SubTitle onClick={() => handleChangingOfFilters(type, { isOpened: !filter.isOpened })}>{subTitle}</SubTitle>
          <ArrowIcon
            direction={filter.isOpened ? ArrowIconDirection.UP : ArrowIconDirection.RIGHT}
            onClick={() =>
              handleChangingOfFilters(type, {
                isOpened: !filter.isOpened,
              })
            }
            color='#A7A7A7'
          />
        </div>
        {filter.isOpened && (
          <>
            <div className={styles.checkboxWrap}>
              {filter.data.map((filterItem, i) => (
                <SortingCheckbox
                  isOption={i === filter.data.length - 1}
                  key={filterItem.id}
                  {...filterItem}
                  handle={handleChangingOfFilters}
                  type={type}
                  isDisable={!filterItem.isChecked && !!filterParams.sorting?.length}
                />
              ))}
            </div>
            {isSortingByZnoScoresChecked && (
              <div className={styles.sortingWrap}>
                {znoScores.isCompleted && <div className={styles.scores}>{`Твої бали: ${scoreArray.join(', ')}`}</div>}
                <div className={styles.btnAddScoresWrap}>
                  <button
                    className={styles.addScores}
                    onClick={() =>
                      setZnoScoresPopupState({
                        ...znoScoresPopupState,
                        isSubjectModalOpen: !znoScoresPopupState.isSubjectModalOpen,
                      })
                    }
                    type='button'
                  >
                    {znoScores.isCompleted ? 'Змінити бали зно' : 'Додати бали ЗНО та атестату'}
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </FiltersBlock>
      <ZnoScoresPopUp setZnoScoresPopupState={setZnoScoresPopupState} znoScoresPopupState={znoScoresPopupState} />
    </div>
  );
};
