import { KeyboardEvent, useState } from 'react';
import { useOffersContext } from '../../layouts/OffersContext';
import styles from './InputZnoScores.module.scss';

const min = 1;

export const InputZnoScore = ({
  placeholder,
  subjectId,
  max,
  regex,
}: {
  placeholder: string;
  subjectId: number;
  max: number;
  regex: RegExp;
}) => {
  const { handleSelectOfZnoScores, znoScores } = useOffersContext();
  const [isValid, setIsValid] = useState<null | boolean>(true);

  const filterValue = (value: string, subjectId: number) => {
    let znoScore;
    switch (true) {
      case !value: {
        setIsValid(null);
        znoScore = null;
        break;
      }
      case +value > max || +value < min || value.length > 4 || !regex.test(value): {
        setIsValid(false);
        setTimeout(() => setIsValid(true), 300);
        znoScore = null;
        break;
      }
      default: {
        setIsValid(true);
        znoScore = value;
      }
    }

    if (znoScore === null && !value) {
      handleSelectOfZnoScores(subjectId, null, false);
    } else if (znoScore) {
      handleSelectOfZnoScores(subjectId, znoScore);
    }
  };

  const handleEsc = (event: KeyboardEvent<HTMLElement>) => {
    if (event.keyCode === 27) {
      event.preventDefault();
      setIsValid(true);
    }
  };

  return (
    <div className={styles.inputWrap}>
      <input
        className={isValid ? styles.inputScore : `${styles.inputScore} ${styles.error}`}
        id={`inputScore${subjectId}`}
        value={String(znoScores.data[subjectId] || '')}
        type='text'
        title='Значення повинно бути у діапазоні від 1 до 200'
        onKeyDown={handleEsc}
        onChange={(e) => filterValue(e.target.value, subjectId)}
        required
      />
      <label htmlFor={`inputScore${subjectId}`}>{placeholder}</label>
    </div>
  );
};
