export const regions = [
  {
    id: 80,
    name: 'КИЇВ',
  },
  {
    id: 5,
    name: 'Вінницька область',
  },
  {
    id: 7,
    name: 'Волинська область',
  },
  {
    id: 12,
    name: 'Дніпропетровська область',
  },
  {
    id: 14,
    name: 'Донецька область',
  },
  {
    id: 18,
    name: 'Житомирська область',
  },
  {
    id: 21,
    name: 'Закарпатська область',
  },
  {
    id: 23,
    name: 'Запорізька область',
  },
  {
    id: 26,
    name: 'Івано-Франківська область',
  },
  {
    id: 32,
    name: 'Київська область',
  },
  {
    id: 35,
    name: 'Кіровоградська область',
  },
  {
    id: 44,
    name: 'Луганська область',
  },
  {
    id: 46,
    name: 'Львівська область',
  },
  {
    id: 48,
    name: 'Миколаївська область',
  },
  {
    id: 51,
    name: 'Одеська область',
  },
  {
    id: 53,
    name: 'Полтавська область',
  },
  {
    id: 56,
    name: 'Рівненська область',
  },
  {
    id: 59,
    name: 'Сумська область',
  },
  {
    id: 61,
    name: 'Тернопільська область',
  },
  {
    id: 63,
    name: 'Харківська область',
  },
  {
    id: 65,
    name: 'Херсонська область',
  },
  {
    id: 68,
    name: 'Хмельницька область',
  },
  {
    id: 71,
    name: 'Черкаська область',
  },
  {
    id: 73,
    name: 'Чернівецька область',
  },
  {
    id: 74,
    name: 'Чернігівська область',
  },
];
