/* eslint-disable */
// TODO: needs some refactor and fresh view
import { FilterDataItem } from '../interfaces/filter-item.interface';
import { EducationBasesTypes } from '../filters/education_bases';

export const initFilterItem = (
  values: {
    id: number;
    name: string;
    emoji?: string;
    descr?: string;
    short_name?: string;
    name_en?: string;
    filters?: {
      id: number;
      name: string;
      emoji: string;
      // eslint-disable-next-line camelcase
      field_id: number;
    }[];
  }[],
  queryValues: any[] | undefined,
  defaultIds?: string[],
) =>
  values.reduce((result, filterItem) => {
    const isChecked = defaultIds?.some((e) => +e === filterItem.id) || !!queryValues?.some((e) => +e === filterItem.id);
    result.push({
      id: filterItem.id,
      name: filterItem.name,
      isDefault: !!defaultIds?.some((e) => +e === filterItem.id),
      isDisabled: filterItem.id === EducationBasesTypes.MASTER,
      ...(filterItem.filters && {
        isNestedOpen: false,
      }),
      ...(filterItem.descr && {
        descr: filterItem.descr,
      }),
      isChecked,

      ...(filterItem.emoji && {
        emoji: filterItem.emoji,
      }),
      ...(filterItem.filters && {
        filters: [
          ...filterItem.filters.map((object) => ({
            ...object,
            isChecked: !!queryValues?.some((e) => +e === object.id),
          })),
        ],
      }),
    });
    return result;
  }, [] as FilterDataItem[]);
