/* eslint-disable */
import cx from 'classnames';
import * as React from 'react';
import { useEffect } from 'react';

import { basic } from '../../common/constants';
import nextFrame from '../../utils/nextFrame';
import './index.scss';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import Icon from '../icon';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE, LANGUAGES } from '../../common/i18n';
import HeaderMenuButton from './HeaderMenuButton';
import BurgerButton from './BurgerButton';

export enum HeaderType {
  LIGHT = 'LIGHT',
  DARK = 'dark',
}

export const HeaderMenuGroup = ({ buttons }: { buttons?: React.ReactNode[] }) => (
  <div className='AppHeaderMenuGroup'>
    {buttons?.map?.((button, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <React.Fragment key={i}>
        {i > 0 && (
          <div className='AppHeaderMenuGroup_Separator'>
            <span />
          </div>
        )}
        {button}
      </React.Fragment>
    ))}
  </div>
);

export const HeaderMenu = ({ open, type = HeaderType.DARK }: { type?: HeaderType; open?: boolean }) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      document.querySelector('body')?.classList.add('hidden');
    } else {
      document.querySelector('body')?.classList.remove('hidden');
    }
  }, [open]);

  return (
    <div
      className={
        type === HeaderType.LIGHT ? cx('AppHeaderMenu', 'AppHeaderMenuLight', { open }) : cx('AppHeaderMenu', { open })
      }
    >
      <div>
        <HeaderMenuGroup
          buttons={[
            <HeaderMenuButton emoji='/emoji/ukraine.png' name={t('header.searchOffers')} href='/offers' />,
            <HeaderMenuButton emoji='/emoji/checkedMark.png' name={t('header.savedOffers')} href='/offers/saved' />,
          ]}
        />
        <HeaderMenuGroup
          buttons={[
            <HeaderMenuButton
              emoji='/emoji/graduationCap.png'
              name={t('header.studSearch')}
              href={`${basic.studsearchUrl}`}
            />,
            <HeaderMenuButton
              emoji='/emoji/chartIncreasing.png'
              name={t('header.universityRanking')}
              href={`${basic.studsearchUrl}/rating`}
            />,
            <HeaderMenuButton emoji='/emoji/thumbsUp.png' name={t('header.znoScores')} href='/rating' />,
            <HeaderMenuButton emoji='/emoji/wavingHand.png' name={t('header.blog')} href='/blog' />,
          ]}
        />
        <HeaderMenuGroup
          buttons={[
            <HeaderMenuButton emoji='/emoji/hands.png' name={t('header.aboutUs')} href={'/about'} />,
            <HeaderMenuButton
              emoji='/emoji/callMeHand.png'
              name={t('header.contactUs')}
              href='https://t.me/vhrechukha'
            />,
          ]}
        />
        <HeaderMenuGroup
          buttons={[
            <HeaderMenuButton
              emoji='/emoji/moneyWithWings.png'
              name={t('header.donateServers')}
              href='https://send.monobank.ua/jar/3UNSF6txsH'
            />,
          ]}
        />
      </div>
    </div>
  );
};

export const useAnimated = (def: boolean, delay = 250) => {
  const [state, setState] = React.useState<boolean>(def);
  const [delayedState, setDelayedState] = React.useState(state);
  useEffect(() => {
    if (!state) {
      const timeout = setTimeout(() => setDelayedState(state), delay);
      return () => clearTimeout(timeout);
    }
  }, [state]);

  return [
    state,
    delayedState,
    (newState: boolean) => {
      if (newState) {
        setDelayedState(true);
        nextFrame(() => setState(newState));
      } else setState(newState);
    },
  ] as const;
};

const Header = ({ type = HeaderType.DARK }: { type?: HeaderType }) => {
  const { i18n } = useTranslation();

  const changeLanguage = () => {
    const newLanguage = i18n.language === DEFAULT_LANGUAGE ? LANGUAGES.en : DEFAULT_LANGUAGE;

    if (newLanguage !== i18n.language) {
      i18n.changeLanguage(newLanguage);
      localStorage.setItem('selectedLanguage', newLanguage);
    }
  };

  const [open, openDelayed, setOpen] = useAnimated(false);

  React.useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setOpen(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <Grid className={type === HeaderType.LIGHT ? cx('AppHeader', 'AppHeaderLight') : 'AppHeader'}>
        <Grid>
          <Grid item container>
            <Link to='/'>
              <img src={type === HeaderType.LIGHT ? '/emoji/logo.svg' : '/emoji/logo_black.svg'} alt='Logo' />
            </Link>
          </Grid>
          <Grid item container justifyContent='flex-end' alignItems='center'>
            <Icon
              style={{ cursor: 'pointer' }}
              onClick={() => changeLanguage()}
              width='40'
              height='40'
              icon={i18n.language}
            />
            <BurgerButton value={open} setValue={setOpen} />
          </Grid>
        </Grid>
      </Grid>
      {(open || openDelayed) && <HeaderMenu type={type} open={open} />}
    </>
  );
};

export default Header;
