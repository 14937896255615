import styles from './OffersSaved.module.scss';
import { HeaderType } from '../../../../components/Header/Header';
import { OfferCard } from '../../components/OfferCard/OfferCard';
import { OffersPagination } from '../../components/OffersPagination/OffersPagination';
import { useOffersContext } from '../../layouts/OffersContext';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Header } from '../../../../components/Header';

export const OffersSaved = () => {
  const { universityStorageOffers, manageStorageUniversities } = useOffersContext();

  const [paginationOfSavedOffers, setPaginationOfSavedOffers] = useState({
    currentPage: 1,
    perPage: 4,
  });

  const handlePaginationOfSavedOffers = (event: SyntheticEvent, value: number) => {
    setPaginationOfSavedOffers({
      ...paginationOfSavedOffers,
      currentPage: value,
    });
  };

  const { currentPage, perPage } = paginationOfSavedOffers;

  const lastIndex = useMemo(() => currentPage * perPage, [currentPage, perPage]);
  const firstIndex = useMemo(() => currentPage * perPage - perPage, [currentPage, perPage]);

  const currentSavedOffers = universityStorageOffers.slice(firstIndex, lastIndex);

  const goOnPreviousPagePaginationOfSavedOffers = () => {
    if (currentSavedOffers.length < 2 && universityStorageOffers.length) {
      setPaginationOfSavedOffers({
        ...paginationOfSavedOffers,
        currentPage: currentPage - 1,
      });
    }
  };

  return (
    <div className={styles.offersSavedWrap}>
      <Header type={HeaderType.LIGHT} />
      <h2 className={styles.title}>Збережені конкурсні пропозиції</h2>
      {universityStorageOffers.length == 0 && (
        <p className={styles.message}>Поки не збережена жодна конкурсна пропозиція</p>
      )}
      {universityStorageOffers.length > 0 && (
        <div className={styles.contentContainer}>
          <div className={styles.offersWrap}>
            {currentSavedOffers.map((card, i) => (
              <OfferCard
                key={i}
                card={card}
                isSaved={universityStorageOffers.some((item) => item.id === card.id)}
                handleStorageUniversities={() => {
                  manageStorageUniversities(card);
                  goOnPreviousPagePaginationOfSavedOffers();
                }}
              />
            ))}
          </div>
          <div className={styles.paginationWrap}>
            <OffersPagination
              count={Math.ceil(universityStorageOffers.length / paginationOfSavedOffers.perPage)}
              handle={handlePaginationOfSavedOffers}
              page={paginationOfSavedOffers.currentPage}
            />
          </div>
        </div>
      )}
    </div>
  );
};
