import styles from './ShowResultBtn.module.scss';

export const ShowResultBtn = ({
  children,
  isDisable,
  ...rest
}: {
  onClick: () => void;
  children: string;
  isDisable?: boolean;
}) => (
  <button disabled={isDisable} {...rest} className={styles.showResultBtn} type='button'>
    {children}
  </button>
);
