import { Box, Pagination, createTheme, ThemeProvider } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#F59D33',
    },
  },
});

export const OffersPagination = ({ count, handle, page }: { count: number; handle: any; page: number }) => {
  return (
    <Box justifyContent={'center'} display={'flex'} sx={{ margin: '20px 0' }}>
      <ThemeProvider theme={theme}>
        <Pagination count={count} onChange={handle} color='primary' page={page} />
      </ThemeProvider>
    </Box>
  );
};
