export const ENVIRONMENTS = {
  local: 'local',
  development: 'development',
  production: 'production',
};

export const ENVS = {
  BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST,
  FRONTEND_HOST: process.env.REACT_APP_FRONTEND_HOST,
  CURRENT_ENV: process.env.REACT_APP_ENV || ENVIRONMENTS.local,
};

export const basic = {
  backendUrl: ENVS.BACKEND_HOST,
  studsearchUrl: 'https://studsearch.org',
};

export const DEFAULT_UNIVERSITY_EMOJI = '🏛';
