import { CloseModalIcon } from '../../../icons/CloseModalIcon';
import { Title } from '../../Title/Title';
import styles from './PopUpZnoScores.module.scss';

export const PopUpZnoScores = ({
  isOpenedZnoScoresFilterPopUp,
  setIsOpenedZnoScoresFilterPopUp,
  handleSubjectModal,
}: {
  isOpenedZnoScoresFilterPopUp: boolean;
  setIsOpenedZnoScoresFilterPopUp: (value: boolean) => void;
  handleSubjectModal: () => void;
}) => (
  <div
    onClick={() => setIsOpenedZnoScoresFilterPopUp(false)}
    className={isOpenedZnoScoresFilterPopUp ? `${styles.modal} ${styles.active}` : styles.modal}
  >
    <div onClick={(e) => e.stopPropagation()} className={styles.modalContent}>
      <div className={styles.headerWrap}>
        <div>
          <Title>Спочатку введи свої бали</Title>
        </div>
        <div>
          <CloseModalIcon onClick={() => setIsOpenedZnoScoresFilterPopUp(false)} />
        </div>
      </div>
      <div className={styles.descriptionWrap}>
        <p>
          Для цього сортування ми вираховуємо твій конкурсний бал для <span>кожної</span> конкурсної пропозиції.
        </p>
        <p> Бал залежить від коефіцієнтів, які у кожної пропозиції свої і відрізняються </p>
        <p>
          Вираховувши, порівнюємо з <br /> прохідними балами минулих років і показуємо тобі найбільш підходящі.
        </p>
      </div>
      <div className={styles.btnWrap}>
        <button onClick={handleSubjectModal} className={styles.btnZno} type='button'>
          Ввести бали ЗНО та атестату
        </button>
      </div>
    </div>
  </div>
);
