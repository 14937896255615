import { useOffersContext } from '../../../layouts/OffersContext';
import { getDisabilityState } from '../../../../../utils/getDisabilityState';
import { Subject, subjects } from '../../../filters/subjects';
import { InputZnoScore } from '../../InputZnoScore/InputZnoScore';
import { ModalFilters } from '../../ModalFilters/ModalFilters';
import { ZnoModalBase } from '../../ZnoModalBase/ZnoModalBase';
import styles from './SelectZnoScores.module.scss';

export const SelectZnoScores = ({
  isOpen,
  handleModal,
  handle,
  closeStep,
}: {
  isOpen: boolean;
  handleModal: () => void;
  handle: () => void;
  closeStep: () => void;
}) => {
  const { znoScores } = useOffersContext();
  const idOfSubjects = Object.keys(znoScores.data);
  const selectedSubjects = idOfSubjects
    .map((idOfSelectedSubject) => subjects.find((subject: Subject) => subject.id === +idOfSelectedSubject))
    .filter((item) => item && item.id !== 100) as unknown as Subject[];

  return (
    <ModalFilters isOpen={isOpen} handle={handleModal}>
      <ZnoModalBase
        nextTitle='Далі'
        title='Введи бали отримані на ЗНО 🎯'
        step='2'
        handle={handle}
        closeStep={closeStep}
        disabled={getDisabilityState(Object.values(znoScores.data))}
      >
        <div className={styles.inputsWrap}>
          {selectedSubjects.map((subject: Subject) => {
            return (
              <InputZnoScore
                key={subject.id}
                placeholder={`Бал з ${subject.subjectName}`}
                subjectId={subject.id}
                max={200}
                regex={/^\d+$/}
              />
            );
          })}
        </div>
      </ZnoModalBase>
    </ModalFilters>
  );
};
