export const CloseModalIcon = ({ ...rest }) => (
  <svg
    {...rest}
    cursor='pointer'
    width='35'
    height='34'
    viewBox='0 0 35 34'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M8.64062 25.4851L25.6112 8.51455' stroke='#6D757A' strokeWidth='2' strokeLinecap='round' />
    <path d='M25.6113 25.4851L8.64077 8.51455' stroke='#6D757A' strokeWidth='2' strokeLinecap='round' />
  </svg>
);
