import { Virtuoso } from 'react-virtuoso';

import { useOffersContext } from '../../layouts/OffersContext';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { CloseModalIcon } from '../../icons/CloseModalIcon';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { InputUniversity } from '../InputUniversity/InputUniversity';
import { ModalFilters } from '../ModalFilters/ModalFilters';
import { ModalTitle } from '../ModalTitle/ModalTitle';
import { ShowResultBtn } from '../ShowMoreBtn/ShowResultBtn';
import { SubTitle } from '../SubTitle/SubTitle';
import { UniversityCheckbox } from '../UniversityCheckbox/UniversityCheckbox';
import styles from './UniversityFilter.module.scss';
import { useState } from 'react';

export const UniversityFilter = ({ subTitle }: { subTitle: string }) => {
  const [isOpen, setOpen] = useState(false);

  const {
    countOfOffers,
    selectedUniversities,
    universities,
    handleChangeOfSelectedUniversities,
    phrase,
    fetchUniversities,
  } = useOffersContext();

  return (
    <div className={styles.wrap}>
      <FiltersBlock>
        <div className={styles.wrapBlock}>
          <div className={styles.titleWrap}>
            <SubTitle onClick={() => setOpen(true)}>{subTitle}</SubTitle>
            {!isOpen && selectedUniversities.length !== 0 && (
              <div className={styles.checkedUniversitiesWrap}>
                <p>{selectedUniversities.length}</p>
              </div>
            )}
          </div>
          <ArrowIcon direction={ArrowIconDirection.RIGHT} onClick={() => setOpen(true)} color='#A7A7A7' />
        </div>
      </FiltersBlock>
      <ModalFilters isOpen={isOpen} handle={() => setOpen(false)}>
        <div className={styles.modalWrap}>
          <div className={styles.headerWrap}>
            <div>
              <ModalTitle>{subTitle}</ModalTitle>
            </div>
            <div>
              <CloseModalIcon onClick={() => setOpen(false)} />
            </div>
          </div>
          <div className={styles.inputWrap}>
            <InputUniversity phrase={phrase} />
          </div>
          <div className={styles.checkboxWrap}>
            <Virtuoso
              style={{ height: '100%' }}
              data={universities}
              endReached={fetchUniversities}
              overscan={20}
              itemContent={(index, filterData) => (
                <UniversityCheckbox key={index} handle={handleChangeOfSelectedUniversities} filterData={filterData} />
              )}
            />
          </div>
          <div className={styles.btnWrap}>
            <ShowResultBtn onClick={() => setOpen(false)}>
              {!countOfOffers ? 'Показати результати' : `Показати результати (${countOfOffers})`}
            </ShowResultBtn>
          </div>
        </div>
      </ModalFilters>
    </div>
  );
};
