export interface Subject {
  emoji: string;
  subjectName: string;
  id: number;
}

export const subjects: Subject[] = [
  {
    id: 100,
    subjectName: 'Середній бал документа про освіту',
    emoji: '📃',
  },
  {
    id: 101,
    subjectName: 'Бал за успішне закінчення підготовчих курсів закладу освіти',
    emoji: '📃',
  },
  {
    id: 1,
    subjectName: 'Українська мова',
    emoji: '🇺🇦',
  },
  {
    id: 6,
    subjectName: 'Історія України',
    emoji: '📅',
  },
  {
    id: 14,
    subjectName: 'Математика',
    emoji: '➕',
  },
  {
    id: 19,
    subjectName: 'Географія',
    emoji: '🗺️',
  },
  {
    id: 104,
    subjectName: 'Бал призерам ІІІ етапу Всеукраїнських конкурсів фахової майстерності',
    emoji: '🎖️',
  },
  {
    id: 18,
    subjectName: 'Біологія',
    emoji: '🧬',
  },
  {
    id: 3,
    subjectName: 'Іноземна мова',
    emoji: '🏳️',
  },
  {
    id: 21,
    subjectName: 'Фізика',
    emoji: '🔬',
  },
  {
    id: 22,
    subjectName: 'Хімія',
    emoji: '🧪',
  },
  {
    id: 105,
    subjectName: 'Бал за мотиваційний лист',
    emoji: '📃',
  },
  {
    id: 103,
    subjectName: 'Інші показники конкурсного відбору',
    emoji: '📃',
  },
  {
    id: 29,
    subjectName: 'Українська мова і література',
    emoji: '🌻',
  },
  {
    id: 30,
    subjectName: 'Англійська мова',
    emoji: '🇬🇧',
  },
  {
    id: 31,
    subjectName: 'Французька мова',
    emoji: '🇫🇷',
  },
  {
    id: 32,
    subjectName: 'Німецька мова',
    emoji: '🇩🇪',
  },
  {
    id: 33,
    subjectName: 'Іспанська мова',
    emoji: '🇪🇸',
  },
];
