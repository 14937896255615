import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { Title } from '../Title/Title';
import styles from './Header.module.scss';

export const OffersHeader = ({ handleClearingOfFilters }: { handleClearingOfFilters: () => void }) => (
  <div className={styles.wrap}>
    <div className={styles.wrapBlock}>
      <ArrowIcon direction={ArrowIconDirection.LEFT} className={styles.arrowLeftIcon} color='#A7A7A7' />
      <Title>Фільтри</Title>
    </div>
    <div onClick={handleClearingOfFilters}>
      <p className={styles.clearButton}>Очистити все</p>
    </div>
  </div>
);
