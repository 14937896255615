import { useOffersContext } from '../../../layouts/OffersContext';
import { getValidityOfAverageCertificateScore } from '../../../../../utils/getValidityOfAverageCertificateScore';
import { InputZnoScore } from '../../InputZnoScore/InputZnoScore';
import { ModalFilters } from '../../ModalFilters/ModalFilters';
import { ZnoModalBase } from '../../ZnoModalBase/ZnoModalBase';
import styles from './SelectAverageScore.module.scss';

export const SelectAverageScore = ({
  isOpen,
  handleModal,
  handleGetSortingResult,
  closeStep,
}: {
  isOpen: boolean;
  handleModal: () => void;
  handleGetSortingResult: () => void;
  closeStep: () => void;
}) => {
  const { znoScores } = useOffersContext();

  return (
    <ModalFilters isOpen={isOpen} handle={handleModal}>
      <ZnoModalBase
        nextTitle={znoScores.data[100] ? 'Розрахувати конкурсний бал' : 'Готово'}
        title='Введи середній бал атестату 🎯'
        step='3'
        handle={handleGetSortingResult}
        closeStep={closeStep}
        disabled={getValidityOfAverageCertificateScore(znoScores.data[100])}
      >
        <div className={styles.inputWrap}>
          <InputZnoScore subjectId={100} placeholder='Середній бал атестату' max={12} regex={/^[0-9]*\.?[0-9]*$/} />
        </div>
      </ZnoModalBase>
    </ModalFilters>
  );
};
