import { FilterItem, IFilterItemExtended } from '../pages/Offers/interfaces/filter-item.interface';
import { EXTENDED_FILTER_TYPES, FilterTypes } from '../pages/Offers/types/filter.type';

export const getIsFiltersDataChecked = (obj: {
  sorting: FilterItem;
  education_forms: FilterItem;
  regions: FilterItem;
  specialities: FilterItem;
  offer_types: FilterItem;
  education_bases: FilterItem;
}) => {
  for (const [filterType, filterGroup] of Object.entries(obj)) {
    if (EXTENDED_FILTER_TYPES.includes(filterType as FilterTypes)) {
      for (const filterArray of filterGroup.data) {
        const extendedFilterGroup = filterArray as IFilterItemExtended;

        if (extendedFilterGroup.filters.some((item) => item.isChecked)) {
          return true;
        }
      }
    } else {
      if (filterGroup.data.some((item) => item.isChecked)) {
        return true;
      }
    }
  }

  return false;
};
