// eslint-disable-next-line no-use-before-define
import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { OffersLayout } from './pages/Offers/layouts/OffersLayout';
import { Offers } from './pages/Offers/Offers';
import MyRatingApp from './pages/Rating';
import Home from './pages/Home';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import Blog from './pages/Blog';
import AboutUs from './pages/AboutUs';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/rating/*' element={<MyRatingApp />} />
        <Route
          path='/offers/*'
          element={
            <OffersLayout>
              <Offers />
            </OffersLayout>
          }
        />
        <Route path='/blog/*' element={<Blog />} />
        <Route path='/about/*' element={<AboutUs />} />
        <Route path='/' element={<Home />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
