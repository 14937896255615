import { FilterDataItem } from '../interfaces/filter-item.interface';
import { EXTENDED_FILTER_TYPES, FilterTypes } from '../types/filter.type';
import { EducationBasesTypes } from '../filters/education_bases';

export const resetFilterItem = (
  values: {
    id: number;
    name: string;
    emoji?: string;
    descr?: string;
    short_name?: string;
    name_en?: string;
    filters?: {
      id: number;
      name: string;
      emoji: string;
      // eslint-disable-next-line camelcase
      field_id: number;
    }[];
  }[],
  filterType: string,
  defaultIds?: string[],
) =>
  values.map((item) => {
    const isBachelorFilterApplied = item.id === EducationBasesTypes.BACHELOR;
    if (EXTENDED_FILTER_TYPES.includes(filterType as FilterTypes)) {
      const resetedFilters = item.filters?.map((i) => ({
        ...i,
        isChecked: isBachelorFilterApplied,
      }));
      return { ...item, filters: resetedFilters } as FilterDataItem;
    } else {
      return {
        ...item,
        isChecked: isBachelorFilterApplied,
        isDefault: !!defaultIds?.some((e) => +e === item.id),
      } as FilterDataItem;
    }
  });
