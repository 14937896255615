import { specialities } from '../filters/specialities';

export const DEFAULT_SPECIALITY_EMOJI = '🎓';

export const getSpecialityEmojiById = (id: number | undefined) => {
  if (id) {
    for (const field of specialities) {
      const filter = field.filters.find((filter) => filter.id === id);
      if (filter) {
        return filter.emoji;
      }
    }
  }

  return DEFAULT_SPECIALITY_EMOJI;
};
