import { useOffersContext } from '../../layouts/OffersContext';
import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { FilterItem, IFilterItemExtended } from '../../interfaces/filter-item.interface';
import { FilterTypes } from '../../types/filter.type';
import { CardCheckbox } from '../CardCheckbox/CardCheckbox';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import FiltersBlock from '../FilterBlock/FilterBlock';
import { SubTitle } from '../SubTitle/SubTitle';
import styles from './Card.module.scss';

export const Card = ({
  type,
  subTitle,
  isSortingOptionChecked,
  handleAddZnoScores,
}: {
  type: FilterTypes;
  subTitle: string;
  handleFilterSearch?: () => void | null;
  isSortingOptionChecked?: boolean;
  handleAddZnoScores?: () => void;
}) => {
  const { handleChangingOfFilters, filters } = useOffersContext();
  const filter: FilterItem = filters[type];

  // eslint-disable-next-line no-prototype-builtins
  const isNestedFilter = filter?.data?.some((filter) => filter.hasOwnProperty('filters'));
  const filtersDataToShow = filter.showMore ? filter.data : filter.data.slice(0, 5);
  const countOfLeftFilters = filter.showMore ? 5 : filter.data.length;

  return (
    <div className={styles.wrap}>
      <FiltersBlock>
        <div className={styles.wrapBlock}>
          <SubTitle
            onClick={() =>
              handleChangingOfFilters(type, {
                isOpened: !filter.isOpened,
              })
            }
          >
            {subTitle}
          </SubTitle>
          <ArrowIcon
            direction={filter.isOpened ? ArrowIconDirection.UP : ArrowIconDirection.RIGHT}
            color='#A7A7A7'
            onClick={() =>
              handleChangingOfFilters(type, {
                isOpened: !filter.isOpened,
              })
            }
          />
        </div>
        {filter.isOpened && (
          <div>
            {!isNestedFilter && filtersDataToShow.length
              ? filtersDataToShow.map((filter) => (
                  <CardCheckbox key={filter.id} {...filter} handle={handleChangingOfFilters} type={type} />
                ))
              : (filtersDataToShow as IFilterItemExtended[]).map((extendedFilter) => (
                  <div key={extendedFilter.id}>
                    <div
                      className={
                        extendedFilter.isNestedOpen ? styles.wrapFilter : `${styles.wrapFilter} ${styles.closed}`
                      }
                    >
                      <div className={styles.extendedFilterTitle}>
                        <span>{extendedFilter.emoji}</span>
                        <CheckboxTitle
                          key={extendedFilter.id}
                          isDisabled={extendedFilter.isDisabled}
                          onClick={() =>
                            handleChangingOfFilters(type, {
                              data: { ...extendedFilter, isNestedOpen: !extendedFilter.isNestedOpen },
                            })
                          }
                        >
                          {`${extendedFilter.name} (${extendedFilter.id})`}
                        </CheckboxTitle>
                      </div>
                      <ArrowIcon
                        direction={extendedFilter.isNestedOpen ? ArrowIconDirection.UP : ArrowIconDirection.RIGHT}
                        onClick={() =>
                          handleChangingOfFilters(type, {
                            data: { ...extendedFilter, isNestedOpen: !extendedFilter.isNestedOpen },
                          })
                        }
                      />
                    </div>
                    {extendedFilter.isNestedOpen &&
                      filtersDataToShow.length &&
                      extendedFilter.filters.map((extendedFilter) => (
                        <CardCheckbox
                          key={extendedFilter.id}
                          {...extendedFilter}
                          handle={handleChangingOfFilters}
                          type={type}
                        />
                      ))}
                  </div>
                ))}
            {typeof filter.showMore === 'boolean' && !filter.showMore && (
              <p
                className={styles.showMore}
                onClick={() =>
                  handleChangingOfFilters(type, {
                    showMore: !filter.showMore,
                  })
                }
              >
                Показати ще {countOfLeftFilters}
              </p>
            )}
            {type === FilterTypes.education_bases && isSortingOptionChecked && (
              <div className={styles.btnAddScoresWrap}>
                <button className={styles.addScores} onClick={handleAddZnoScores} type='button'>
                  Додати бали ЗНО та атестату
                </button>
              </div>
            )}
          </div>
        )}
      </FiltersBlock>
    </div>
  );
};
