import { useEffect } from 'react';
import { Virtuoso } from 'react-virtuoso';

import { HeaderType } from '../../../../components/Header/Header';
import styles from './OffersResult.module.scss';
import { FiltersChip } from '../../components/FiltersChip/FiltersChip';
import { useOffersContext } from '../../layouts/OffersContext';
import { OfferCard } from '../../components/OfferCard/OfferCard';
import { Header } from '../../../../components/Header';

export const OffersResult = () => {
  const {
    handleFiltersChip,
    offers,
    manageStorageUniversities,
    fetchOffers,
    universityStorageOffers,
    handleResetOffersAndOffersPage,
  } = useOffersContext();

  useEffect(() => {
    fetchOffers();
    document.body.style.overflow = 'hidden';

    return () => {
      handleResetOffersAndOffersPage();
      document.body.style.overflow = 'visible';
    };
  }, []);

  return (
    <div className={styles.offersResultWrap}>
      <div>
        <Header type={HeaderType.LIGHT} />
        <div className={styles.chipsWrap}>
          <FiltersChip handleFiltersChip={handleFiltersChip} />
        </div>
      </div>
      <div className={styles.cardsWrap}>
        {offers.length === 0 && <p className={styles.message}>Не знайдена жодна конкурсна пропозиція</p>}
        <Virtuoso
          className={styles.virtWrap}
          data={offers}
          endReached={fetchOffers}
          overscan={20}
          itemContent={(index, card) => (
            <OfferCard
              key={index}
              card={card}
              isSaved={universityStorageOffers.some((item) => item.id === card.id)}
              handleStorageUniversities={() => manageStorageUniversities(card)}
            />
          )}
        />
      </div>
    </div>
  );
};
