import { Route, Routes, useLocation } from 'react-router-dom';
import { OffersFilters } from './pages/OffersFilters/OffersFilters';
import { OffersResult } from './pages/OffersResult/OffersResult';
import { OffersSaved } from './pages/OffersSaved/OffersSaved';
import { OfferPage } from './pages/OfferPage/OfferPage';

export const Offers = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route path='/result/:id/:u_id' element={<OfferPage />} />
      <Route path='/result' element={<OffersResult />} />
      <Route path='/saved' element={<OffersSaved />} />
      <Route path='*' element={<OffersFilters />} />
    </Routes>
  );
};
