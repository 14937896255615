import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import App from '../../components/app';
import { HeaderType } from '../../components/Header/Header';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Icon from '../../components/icon';
import { Header } from '../../components/Header';

// TODO: remove duplicates
const AppContentAnimated = ({ children }: { children: React.ReactElement }) => {
  const location = useLocation();

  return (
    <div className='AppContentAnimated'>
      <CSSTransition key={location.pathname} in timeout={1000}>
        <div className='AppContentAnimated_Block'>{children}</div>
      </CSSTransition>
    </div>
  );
};

const Home = () => {
  const { t } = useTranslation();

  return (
    <App>
      <Header type={HeaderType.LIGHT} />
      <AppContentAnimated>
        <Box width='100%' display='flex' minHeight='100%' alignItems='center' justifyContent='center'>
          <Grid item container lg={6} md={6} sm={12} textAlign='center' p='20px'>
            <Typography m='auto' variant='h1' textAlign='center' justifyContent='center' flexWrap='wrap' display='flex'>
              {t('translation:home.title')}
              <Icon width='40' height='40' icon='ua' />
            </Typography>
            <Typography variant='body2' py='10px'>
              {t('translation:home.subTitle')}
            </Typography>
            <Button color='primary' href='/offers'>
              {t('translation:home.button')}
            </Button>
          </Grid>
        </Box>
      </AppContentAnimated>
    </App>
  );
};

export default Home;
