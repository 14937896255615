import { HTMLAttributes } from 'react';

export enum ArrowIconDirection {
  UP = 'UP',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export const ArrowIcon = ({
  direction,
  ...rest
}: { direction: keyof typeof ArrowIconDirection } & HTMLAttributes<SVGSVGElement>) => (
  <>
    {direction === ArrowIconDirection.UP && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <svg
        {...rest}
        cursor='pointer'
        width='16'
        height='12'
        viewBox='0 0 18 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          /* eslint-disable-next-line max-len */
          d='M0.879399 10.0532C1.12553 10.2993 1.45931 10.4375 1.80734 10.4375C2.15536 10.4375 2.48914 10.2993 2.73527 10.0532L9.23214 3.55634L15.729 10.0532C15.9766 10.2923 16.3081 10.4246 16.6522 10.4216C16.9964 10.4186 17.3256 10.2806 17.5689 10.0372C17.8122 9.79388 17.9503 9.46469 17.9533 9.12056C17.9563 8.77642 17.824 8.44488 17.5849 8.19734L10.1601 0.772531C9.91395 0.526475 9.58017 0.388248 9.23214 0.388248C8.88412 0.388248 8.55034 0.526475 8.30421 0.772531L0.879399 8.19734C0.633344 8.44347 0.495117 8.77725 0.495117 9.12528C0.495117 9.47331 0.633344 9.80709 0.879399 10.0532Z'
          fill={rest.color}
        />
      </svg>
    )}
    {direction === ArrowIconDirection.RIGHT && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <svg
        {...rest}
        cursor='pointer'
        width='12'
        height='16'
        viewBox='0 0 8 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          /* eslint-disable-next-line max-len */
          d='M0.459779 0.635804C0.272308 0.823331 0.166992 1.07764 0.166992 1.3428C0.166992 1.60797 0.272308 1.86228 0.459779 2.0498L5.40978 6.9998L0.459779 11.9498C0.277621 12.1384 0.176826 12.391 0.179104 12.6532C0.181383 12.9154 0.286552 13.1662 0.47196 13.3516C0.657368 13.537 0.90818 13.6422 1.17038 13.6445C1.43257 13.6467 1.68518 13.546 1.87378 13.3638L7.53078 7.7068C7.71825 7.51927 7.82356 7.26496 7.82356 6.9998C7.82356 6.73464 7.71825 6.48033 7.53078 6.2928L1.87378 0.635804C1.68625 0.448333 1.43194 0.343018 1.16678 0.343018C0.901614 0.343018 0.647306 0.448333 0.459779 0.635804Z'
          fill={rest.color}
        />
      </svg>
    )}
    {direction === ArrowIconDirection.LEFT && (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <svg
        {...rest}
        cursor='pointer'
        width='12'
        height='16'
        viewBox='0 0 12 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M10.415 17.0479L1.41504 9.04785L10.415 1.04785'
          stroke={rest.color}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
  </>
);
