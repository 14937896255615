import styles from './OfferCard.module.scss';
import { useNavigate } from 'react-router-dom';
import { SortingTypes } from '../../filters/sorting';
import { useOffersContext } from '../../layouts/OffersContext';
import { CheckedOfferMark } from '../../icons/CheckedOfferMark';
import { OfferMark } from '../../icons/OfferMark';
import { getSpecialityEmojiById } from '../../utils/get-speciality-emoji-by-filter-id';
import { OfferItem } from '../../interfaces/filter-item.interface';
import { getMinShortNameOfUniversity } from '../../utils/get-min-short-name-of-university';
import { modifyStringToShortView } from '../../utils/modify-string-to-short-view';
import { DEFAULT_UNIVERSITY_EMOJI } from '../../../../common/constants';

export const OfferCard = ({
  isSaved,
  card,
  handleStorageUniversities,
}: {
  isSaved: boolean;
  card: OfferItem;
  handleStorageUniversities: () => void;
}) => {
  const { filterParams } = useOffersContext();
  const navigate = useNavigate();
  const isZnoSorting = filterParams.sorting?.includes(String(SortingTypes.ZNO_SCORES));
  let message;
  let alertColorFlag = '';

  const goToOfferPage = () => {
    const path = `/offers/result/${card.id}/${card.u_id}`;
    navigate(path);
  };

  if (card.zno_scores) {
    const scoreDifference = +(card.score_difference || 0).toFixed(1);

    if (card.enrolled_budget_mean_score) {
      switch (true) {
        case scoreDifference > 0:
          message = (
            <p>
              Твій конкурсний бал для цієї спеціальності <span>вище на {scoreDifference}</span> за прохідний бал
              минулого року.
            </p>
          );
          alertColorFlag = 'green';
          break;
        case scoreDifference < 0:
          message = (
            <p>
              Твій конкурсний бал для цієї спеціальності <span>нижче на {Math.abs(scoreDifference)}</span> за прохідний
              бал минулого року.
            </p>
          );
          alertColorFlag = 'red';
          break;
        default:
          message = <p>Мінімальний бал зарахованих на контракт {card.enrolled_budget_mean_score}</p>;
          alertColorFlag = 'yellow';
      }
    } else {
      message = <p>На цю спеціальність не було зараховано жодного абітурієнта минулого року</p>;
      alertColorFlag = 'grey';
    }
  }

  const facultyName = modifyStringToShortView(card?.ft_name);
  const shortUniversityName = card?.u_short_name
    ? getMinShortNameOfUniversity(card?.u_short_name)
    : getMinShortNameOfUniversity(card.name);
  const specialityEmoji = getSpecialityEmojiById(card?.speciality_code);

  return (
    <div className={styles.offerCard}>
      <div className={styles.titleWrap}>
        <div className={styles.titleName} onClick={goToOfferPage}>
          <div className={styles.universityNameWrap}>
            <p className={styles.cardId}>{card.ft_id}</p>
            <p>
              {DEFAULT_UNIVERSITY_EMOJI} {shortUniversityName}
            </p>
          </div>
          <div>
            <p>
              {specialityEmoji} {facultyName}
            </p>
          </div>
        </div>
        <div onClick={handleStorageUniversities} className={styles.mark}>
          {isSaved ? <CheckedOfferMark /> : <OfferMark />}
        </div>
      </div>
      <div className={styles.descrWrap}>
        <p className={styles.name}>{card.name}</p>
        <p className={styles.descr}>{card.ft_name}</p>
      </div>
      {isZnoSorting && (
        <div className={`${styles.znoScoreWrap} ${styles[alertColorFlag]}`}>
          <div className={styles.znoScore}>
            <p>{String(Math.round((card.zno_scores as number) * 10) / 10).replace('.', ',')}</p>
          </div>
          <div className={styles.scoreDescr}>{message}</div>
        </div>
      )}
    </div>
  );
};
