export enum SortingTypes {
  ZNO_SCORES,
  STUDENTS_SCORES_DESC,
  STUDENTS_SCORES_ASC,
}
export const sorting = [
  {
    name: 'За балами вступників (від вищих)',
    descr: 'Де вчаться студенти з в середньому кращими балами ЗНО',
    id: SortingTypes.STUDENTS_SCORES_DESC,
  },
  {
    name: 'За балами вступників (від нищих)',
    descr: 'Де вчаться студенти з в середньому гіршими балами ЗНО',
    id: SortingTypes.STUDENTS_SCORES_ASC,
  },
  {
    name: 'За шансами вступити з твоїми балами',
    descr: 'Вираховуємо твій бал для кожної конкурсної пропозиції та порівнюємо з прохідними балами минулих років',
    id: SortingTypes.ZNO_SCORES,
  },
];
