import styles from './OfferHeader.module.scss';
import { BackToOffersBtn } from '../BackToOffersBtn/BackToOffersBtn';
import { useOffersContext } from '../../layouts/OffersContext';
import { OfferMark } from '../../icons/OfferMark';
import { CheckedOfferMark } from '../../icons/CheckedOfferMark';

export const OfferHeader = ({ ...offer }: any) => {
  const { universityStorageOffers, manageStorageUniversities } = useOffersContext();
  const isSaved = universityStorageOffers.some((item) => item.id === offer.id);

  return (
    <div className={styles.headerWrap}>
      <BackToOffersBtn />
      <div className={styles.markWrap} onClick={() => manageStorageUniversities(offer)}>
        {isSaved ? <CheckedOfferMark /> : <OfferMark />}
      </div>
    </div>
  );
};
