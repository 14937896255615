const API = {
  OFFERS: {
    INDEX: '/v1/offers/',
    SEARCH_ZNOS: '/v1/offers/znos',
    SEARCH_SUBJECTS: '/v1/offers/subjects',
    SEARCH_ZNOS_COUNT: '/v1/offers/znos/count',
    SEARCH_SUBJECTS_COUNT: '/v1/offers/subjects/count',
  },
  UNIVERSITIES: {
    SEARCH: '/v1/universities/phrase',
  },
};

export { API };
