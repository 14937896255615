import React from 'react';

import Button from '../../../../components/button';
import { ModalHeader } from '../ModalHeader/ModalHeader';
import styles from './ZnoModalBase.module.scss';

export const ZnoModalBase = ({
  title,
  children,
  nextTitle,
  closeStep,
  step,
  handle,
  disabled,
}: {
  title: string;
  children: React.ReactNode;
  nextTitle: string;
  closeStep: () => void;
  step: string;
  handle: () => void;
  disabled?: boolean;
}) => {
  return (
    <div className={styles.wrap}>
      <ModalHeader title={title} step={step} closeStep={closeStep} />
      <div className={styles.childrenWrap}>{children}</div>
      <div className={styles.btnWrap}>
        <Button style={{ backgroundColor: '#000' }} disabled={disabled} onClick={handle}>
          {nextTitle}
        </Button>
      </div>
    </div>
  );
};
