import { useOffersContext } from '../../layouts/OffersContext';
import { PopUpZnoScores } from './PopUpZnoScores/PopUpZnoScores';
import { SelectAverageScore } from './SelectAverageScore/SelectAverageScore';
import { SelectSubjectModal } from './SelectSubjectModal/SelectSubjectModal';
import { SelectZnoScores } from './SelectZnoScores/SelectZnoScores';

interface ZnoScoresPopupState {
  isSubjectModalOpen: boolean;
  isZnoScoresModalOpen: boolean;
  isAverageScoreModalOpen: boolean;
}

export const ZnoScoresPopUp = ({
  znoScoresPopupState,
  setZnoScoresPopupState,
}: {
  setZnoScoresPopupState: (value: ZnoScoresPopupState) => void;
  znoScoresPopupState: ZnoScoresPopupState;
}) => {
  const { setIsOpenedZnoScoresFilterPopUp, isOpenedZnoScoresFilterPopUp, setZnoScores, znoScores } = useOffersContext();

  const handleSubjectModal = () => {
    setIsOpenedZnoScoresFilterPopUp(false);
    setZnoScoresPopupState({
      ...znoScoresPopupState,
      isSubjectModalOpen: !znoScoresPopupState.isSubjectModalOpen,
    });
  };

  const handleZnoScoreModal = () => {
    setZnoScoresPopupState({
      ...znoScoresPopupState,
      isSubjectModalOpen: !znoScoresPopupState.isSubjectModalOpen,
      isZnoScoresModalOpen: !znoScoresPopupState.isZnoScoresModalOpen,
    });
  };

  const handleAverageModal = () => {
    setZnoScoresPopupState({
      ...znoScoresPopupState,
      isZnoScoresModalOpen: !znoScoresPopupState.isZnoScoresModalOpen,
      isAverageScoreModalOpen: !znoScoresPopupState.isAverageScoreModalOpen,
    });
  };

  const handleGetSortingResult = () => {
    const areAllSubjectsFilled = Object.values(znoScores).every((e) => e !== null);

    if (areAllSubjectsFilled) {
      setZnoScores({
        ...znoScores,
        isCompleted: true,
      });
    }

    setZnoScoresPopupState({
      ...znoScoresPopupState,
      isAverageScoreModalOpen: false,
      isZnoScoresModalOpen: false,
    });
  };
  return (
    <div>
      <PopUpZnoScores
        isOpenedZnoScoresFilterPopUp={isOpenedZnoScoresFilterPopUp}
        setIsOpenedZnoScoresFilterPopUp={setIsOpenedZnoScoresFilterPopUp}
        handleSubjectModal={handleSubjectModal}
      />
      <SelectSubjectModal
        isOpen={znoScoresPopupState.isSubjectModalOpen}
        handleModal={() =>
          setZnoScoresPopupState({
            ...znoScoresPopupState,
            isSubjectModalOpen: false,
          })
        }
        closeStep={handleSubjectModal}
        handle={handleZnoScoreModal}
      />
      <SelectZnoScores
        isOpen={znoScoresPopupState.isZnoScoresModalOpen}
        handleModal={() =>
          setZnoScoresPopupState({
            ...znoScoresPopupState,
            isZnoScoresModalOpen: false,
          })
        }
        closeStep={handleZnoScoreModal}
        handle={handleAverageModal}
      />
      <SelectAverageScore
        isOpen={znoScoresPopupState.isAverageScoreModalOpen}
        handleModal={() =>
          setZnoScoresPopupState({
            ...znoScoresPopupState,
            isAverageScoreModalOpen: false,
          })
        }
        closeStep={handleAverageModal}
        handleGetSortingResult={handleGetSortingResult}
      />
    </div>
  );
};
