export const CheckedOfferMark = ({ ...rest }) => (
  <svg {...rest} xmlns='http://www.w3.org/2000/svg' width='16' height='20' viewBox='0 0 16 20' fill='none'>
    <path
      d='M13.3998 0.399994H2.5998C1.60568 0.399994 0.799805 1.21848 0.799805 2.22816V19.1931C0.799805 19.5067 1.13469 19.702 1.40135 19.544L7.79826 15.7541C7.9228 15.6803 8.07681 15.6803 8.20135 15.7541L14.5983 19.544C14.8649 19.702 15.1998 19.5067 15.1998 19.1931V2.22816C15.1998 1.21848 14.3939 0.399994 13.3998 0.399994ZM13.3998 16.0102C13.3998 16.3238 13.0649 16.5191 12.7983 16.3611L8.20135 13.6376C8.07681 13.5639 7.9228 13.5639 7.79826 13.6376L3.20135 16.3611C2.93469 16.5191 2.5998 16.3238 2.5998 16.0102V2.45668C2.5998 2.39607 2.62351 2.33795 2.66571 2.29509C2.7079 2.25224 2.76513 2.22816 2.8248 2.22816H13.1748C13.2991 2.22816 13.3998 2.33035 13.3998 2.45653V16.0102Z'
      fill='#F59D33'
    />
    <path
      d='M12.7983 16.3611L8.20135 13.6376C8.07681 13.5639 7.9228 13.5639 7.79826 13.6376L3.20135 16.3611C2.93469 16.5191 2.5998 16.3238 2.5998 16.0102V2.45668C2.5998 2.39607 2.62351 2.33795 2.66571 2.29509C2.7079 2.25224 2.76513 2.22816 2.8248 2.22816H13.1748C13.2991 2.22816 13.3998 2.33035 13.3998 2.45653V16.0102C13.3998 16.3238 13.0649 16.5191 12.7983 16.3611Z'
      fill='#F59D33'
    />
  </svg>
);
