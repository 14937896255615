import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { University } from '../../interfaces/filter-item.interface';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import styles from './UniversityCheckbox.module.scss';

export const UniversityCheckbox = ({
  handle,
  filterData,
}: {
  handle: (updatedData: University) => void;
  filterData: University;
}) => (
  <label className={styles.wrap}>
    <input
      type='checkbox'
      checked={filterData.isChecked}
      className={styles.inputCheckBox}
      onChange={() => handle({ ...filterData, isChecked: !filterData.isChecked })}
    />
    <CheckMarkIcon className={styles.checkbox} />
    <div>
      <CheckboxTitle>{filterData.name}</CheckboxTitle>
      <p className={styles.region}>{filterData.region_name}</p>
    </div>
  </label>
);
