import { ZnosFilters } from './types/znos-filters';
import { SubjectsFilters } from './types/subjects-filters';
import { API } from './constants';
import { basic } from '../common/constants';
import { PaginationDto } from './types/pagination';

const getOffersBySubjectsCountReq = async (data: SubjectsFilters): Promise<{ pagination: PaginationDto }> => {
  const response = await fetch(basic.backendUrl + API.OFFERS.SEARCH_SUBJECTS_COUNT, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });

  return await response.json();
};

const getOffersByZnosCountReq = async (data: ZnosFilters): Promise<{ pagination: PaginationDto }> => {
  const response = await fetch(basic.backendUrl + API.OFFERS.SEARCH_ZNOS_COUNT, {
    method: 'post',
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  });

  return await response.json();
};

const getOfferByIdReq = async (id: string, u_id: string) => {
  const response = await fetch(`${basic.backendUrl}${API.OFFERS.INDEX}${u_id}/offers/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
  });

  return await response.json();
};

export { getOffersBySubjectsCountReq, getOffersByZnosCountReq, getOfferByIdReq };
