import { ArrowIcon, ArrowIconDirection } from '../../icons/ArrowIcon';
import { useOffersContext } from '../../layouts/OffersContext';
import styles from './BackToOffersBtn.module.scss';

export const BackToOffersBtn = () => {
  const { handleGoBack } = useOffersContext();
  return (
    <div className={styles.btnWrap} onClick={handleGoBack}>
      <div className={styles.btnDescr}>Назад</div>
      <div className={styles.btnArrow}>
        <ArrowIcon direction={ArrowIconDirection.LEFT} color='#F59D33' />
      </div>
    </div>
  );
};
