import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import styles from './SortingCheckbox.module.scss';

export const SortingCheckbox = ({ handle, type, isOption, isDisable, ...filter }: any) => (
  <div>
    <label className={styles.wrap}>
      <input
        type='checkbox'
        onChange={() => {
          handle(type, { data: { ...filter, isChecked: !filter.isChecked } });
        }}
        disabled={isDisable}
        defaultChecked={filter.isChecked}
        className={styles.inputCheckBox}
      />
      <CheckMarkIcon className={isDisable ? `${styles.checkbox} ${styles.disable}` : styles.checkbox} />
      <div>
        <div className={styles.titleWrap}>
          {filter.emoji && (
            <div className={styles.emojiWrap}>
              <p className={styles.emoi}>{filter.emoji}</p>
            </div>
          )}
          <CheckboxTitle
            onClick={() =>
              handle(type, {
                isOpened: !filter.isOpened,
              })
            }
            isDisabled={isDisable}
          >
            {`${filter.name}`}
          </CheckboxTitle>
        </div>
        <div className={isOption ? `${styles.descrWrap} ${styles.option}` : styles.descrWrap}>
          <p className={styles.description}>{filter.descr}</p>
        </div>
      </div>
    </label>
  </div>
);
