import Header from '../../../../components/Header/Header';
import { useOffersContext } from '../../layouts/OffersContext';
import { Card } from '../../components/Card/Card';
import { OffersHeader } from '../../components/Header/Header';
import { ShowResultBtn } from '../../components/ShowMoreBtn/ShowResultBtn';
import { SortingFilter } from '../../components/SortingFilter/SortingFilter';
import { UniversityFilter } from '../../components/UniversityFilter/UniversityFilter';
import styles from './OffersFilters.module.scss';
import { FilterTypes } from '../../types/filter.type';

export const OffersFilters = () => {
  const { handleGettingResults, countOfOffers, isDisableShowMoreBtn, handleClearingOfFilters } = useOffersContext();

  return (
    <div className={styles.offersWrap}>
      <div className={styles.filtersWrap}>
        <Header />
        <OffersHeader handleClearingOfFilters={handleClearingOfFilters} />
        {[
          { type: FilterTypes.education_bases, title: 'Освітній рівень' },
          { type: FilterTypes.specialities, title: 'Галузь та спеціальність' },
          { type: FilterTypes.regions, title: 'Регіони' },
          { type: FilterTypes.education_forms, title: 'Форма навчання' },
          { type: FilterTypes.offer_types, title: 'Бюджет чи контракт' },
        ].map((item, index) => (
          <Card key={index} subTitle={item.title} type={item.type} />
        ))}
        <UniversityFilter subTitle='ВНЗ (Вищий навчальний заклад)' />
        <SortingFilter subTitle='Сортувати за' type={FilterTypes.sorting} />
      </div>

      <div className={styles.btnWrap}>
        <ShowResultBtn isDisable={isDisableShowMoreBtn} onClick={handleGettingResults}>
          {!countOfOffers ? 'Показати результати' : `Показати результати (${countOfOffers})`}
        </ShowResultBtn>
      </div>
    </div>
  );
};
