import { useEffect } from 'react';

import { SearchIcon } from '../../icons/SearchIcon';
import styles from './InputUniversity.module.scss';
import { useOffersContext } from '../../layouts/OffersContext';

export const InputUniversity = ({ phrase }: { phrase: string }) => {
  const { fetchUniversities, resetFetchedUniversities, handleSetPhrase } = useOffersContext();

  useEffect(() => {
    resetFetchedUniversities();
  }, [phrase]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchUniversities();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [phrase]);
  return (
    <div className={styles.inputWrap}>
      <SearchIcon />
      <input
        className={styles.inputField}
        value={phrase}
        onChange={(e) => handleSetPhrase(e.target.value)}
        placeholder='Назва ВНЗ'
        type='text'
      />
    </div>
  );
};
