import { CheckMarkIcon } from '../../icons/CheckMarkIcon';
import { CheckboxTitle } from '../CheckboxTitle/CheckboxTitle';
import styles from './CardCheckbox.module.scss';

export const CardCheckbox = ({ handle, type, ...filter }: any) => {
  const isCheckedFilter = filter.isDefault ? filter.isChecked : !filter.isChecked;
  return (
    <label className={filter.descr ? styles.wrapWithDesc : styles.wrapWithoutDesc}>
      <input
        type='checkbox'
        onChange={() => handle(type, { data: { ...filter, isChecked: isCheckedFilter } })}
        className={styles.inputCheckBox}
        checked={filter.isChecked}
        disabled={filter.isDisabled}
      />
      <CheckMarkIcon className={styles.checkbox} />
      <div>
        <div className={styles.titleWrap}>
          {filter.emoji && (
            <div className={styles.emojiWrap}>
              <p className={styles.emoi}>{filter.emoji}</p>
            </div>
          )}

          <CheckboxTitle
            onClick={() =>
              handle(type, {
                isOpened: !filter.isOpened,
              })
            }
            isDisabled={filter.isDisabled}
          >
            {`${filter.name}`}
          </CheckboxTitle>
        </div>
        {filter.descr && (
          <div className={styles.descrWrap}>
            <p className={styles.description}>{filter.descr}</p>
          </div>
        )}
      </div>
    </label>
  );
};
