// eslint-disable-next-line no-shadow
export enum FilterTypes {
  regions = 'regions',
  sorting = 'sorting',
  education_forms = 'education_forms',
  specialities = 'specialities',
  offer_types = 'offer_types',
  education_bases = 'education_bases',
}

export const EXTENDED_FILTER_TYPES = [FilterTypes.specialities];

export type FilterType = (typeof FilterTypes)[keyof typeof FilterTypes];
