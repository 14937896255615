import { createTheme } from '@mui/material/styles';

export const settings = {
  fontFamilyMariupol: "'Mariupol', sans-serif",
  fontFamilyInter: "'Inter', sans-serif",

  white: '#FFFFFF',

  darkest100: '#0B1922',
  darkest80: '#3C474E',
  darkest60: '#6D757A',
  darkest40: '#9DA3A7',
  darkest20: '#CED1D3',
  darkest10: '#E7E8E9',
  darkest5: '#F3F3F4',

  primary100: '#F59D33',
  primary60: '#F9C485',
  primary40: '#FBD8AD',
  primary20: '#FDEBD6',
  primary15: '#FDF0E0',
  primary10: '#FEF5EB',

  secondaryBlue100: '#22DDE9',
  secondaryPink100: '#FF3950',
  secondaryPurple100: '#9912F5',
};

let theme = createTheme({});

theme = createTheme(theme, {
  typography: {
    fontFamily: ['Inter', 'sans-serif'].join(','),
    h1: {
      fontFamily: settings.fontFamilyMariupol,
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '40px',
      lineHeight: '109%',
      color: settings.darkest100,
      [theme.breakpoints.only('sm')]: {
        fontSize: '30px',
      },
    },
    h2: {
      fontFamily: settings.fontFamilyMariupol,
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: settings.darkest100,
      fontSize: '20px',
      lineHeight: '100%',
    },
    h3: {
      fontFamily: settings.fontFamilyMariupol,
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: settings.darkest100,
      fontSize: '16px',
      lineHeight: '123.5%',
    },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: {
      fontFamily: settings.fontFamilyInter,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '20px',
      lineHeight: '100%',
      color: settings.darkest80,
    },
    body2: {
      fontFamily: settings.fontFamilyInter,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '123.5%',
      color: settings.darkest80,
    },
    button: {
      textTransform: 'none',
      color: settings.white,
      fontFamily: settings.fontFamilyInter,
      fontWeight: 700,
      fontSize: '16px',
    },
    caption: undefined,
    overline: undefined,
  },
  palette: {
    mode: 'light',
    background: {
      default: settings.darkest20,
    },
    primary: {
      main: settings.primary100,
    },
    secondary: {
      main: settings.secondaryPurple100,
    },
    info: {
      main: settings.white,
      dark: settings.darkest100,
      light: settings.darkest80,
      contrastText: settings.darkest60,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {},
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
        position: 'relative',
      },
      styleOverrides: {
        root: {
          backgroundColor: '#232233',
          color: '#f8f9f8',
          padding: '1rem 0',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          margin: '1rem 0',
        },
      },
    },

    MuiListItemText: {
      styleOverrides: {
        root: {
          color: '#f5f6f6',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: '10px !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          // minWidth: '450px',
          width: '100%',
          marginBottom: '1rem',
          borderRadius: '40px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        disableElevation: true,
        color: 'primary',
      },
      styleOverrides: {
        root: {
          flex: '0 0 auto',
          border: 'none',
          outline: 'none',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: settings.primary100,
          padding: '20px',
          boxSizing: 'border-box',

          textAlign: 'center',
          borderRadius: '12px',

          cursor: 'pointer',
          userSelect: 'none',
          transition: 'opacity 0.1s ease-in-out, transform 0.2s cubic-bezier(0.32, 0, 0.15, 1.75)',
          '&.disabled': {
            opacity: 0.5,
          },
          '&:hover': {
            transform: 'scale(0.99)',
            backgroundColor: settings.primary100,
          },
          '&:active': {
            transform: 'scale(0.95)',
          },
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          transitionDuration: '.3s',
          transitionProperty: 'all',
          ':hover': {
            boxShadow: '0px 5px 5px rgba(0,0,0,0.05)',
            transform: 'scale(1.025)',
          },
        },
      },
    },
    // MuiGrid: {
    //   styleOverrides: {
    //     root: {
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //     },
    //   },
    // },
  },
});

export { theme };
