export const specialities = [
  {
    id: 1,
    name: 'Освіта/Педагогіка',
    emoji: '🎓',
    filters: [
      {
        id: 11,
        name: 'Освітні, педагогічні науки',
        emoji: '🎓',
        field_id: 1,
      },
      {
        id: 12,
        name: 'Дошкільна освіта',
        emoji: '👦',
        field_id: 1,
      },
      {
        id: 13,
        name: 'Початкова освіта',
        emoji: '🏫',
        field_id: 1,
      },
      {
        id: 14,
        name: 'Середня освіта',
        emoji: '🧑‍🏫',
        field_id: 1,
      },
      {
        id: 15,
        name: 'Професійна освіта',
        emoji: '👨‍🏫',
        field_id: 1,
      },
      {
        id: 16,
        name: 'Спеціальна освіта',
        emoji: '🧑‍🎓',
        field_id: 1,
      },
      {
        id: 17,
        name: 'Фізична культура і спорт',
        emoji: '👟',
        field_id: 1,
      },
    ],
  },
  {
    id: 2,
    name: 'Культура і мистецтво',
    emoji: '🎭',
    filters: [
      {
        id: 21,
        name: 'Аудіовізуальне мистецтво та виробництво',
        emoji: '🔊',
        field_id: 2,
      },
      {
        id: 22,
        name: 'Дизайн',
        emoji: '✨',
        field_id: 2,
      },
      {
        id: 23,
        name: 'Образотворче мистецтво, декоративне мистецтво, реставрація',
        emoji: '🎨',
        field_id: 2,
      },
      {
        id: 24,
        name: 'Хореографія',
        emoji: '💃',
        field_id: 2,
      },
      {
        id: 25,
        name: 'Музичне мистецтво',
        emoji: '🎵',
        field_id: 2,
      },
      {
        id: 26,
        name: 'Сценічне мистецтво',
        emoji: '🎭',
        field_id: 2,
      },
      {
        id: 27,
        name: 'Музеєзнавство, пам’яткознавство',
        emoji: '🏛',
        field_id: 2,
      },
      {
        id: 28,
        name: 'Менеджмент соціокультурної діяльності',
        emoji: '💼',
        field_id: 2,
      },
      {
        id: 29,
        name: 'Інформаційна, бібліотечна та архівна справа',
        emoji: '📚',
        field_id: 2,
      },
    ],
  },
  {
    id: 3,
    name: 'Гуманітарні науки',
    emoji: '📘',
    filters: [
      {
        id: 31,
        name: 'Релігієзнавство',
        emoji: '🙏',
        field_id: 3,
      },
      {
        id: 32,
        name: 'Історія та археологія',
        emoji: '🏺',
        field_id: 3,
      },
      {
        id: 33,
        name: 'Філософія',
        emoji: '🌌',
        field_id: 3,
      },
      {
        id: 34,
        name: 'Культурологія',
        emoji: '🐉',
        field_id: 3,
      },
      {
        id: 35,
        name: 'Філологія',
        emoji: '🔤',
        field_id: 3,
      },
    ],
  },
  {
    id: 4,
    name: "Богослов'я",
    emoji: '⛪',
    filters: [
      {
        id: 41,
        name: 'Богослов’я',
        emoji: '⛪',
        field_id: 4,
      },
    ],
  },
  {
    id: 5,
    name: 'Соціальні та поведінкові науки',
    emoji: '🧑‍🤝‍🧑',
    filters: [
      {
        id: 51,
        name: 'Економіка',
        emoji: '📈',
        field_id: 5,
      },
      {
        id: 52,
        name: 'Політологія',
        emoji: '🗳️',
        field_id: 5,
      },
      {
        id: 53,
        name: 'Психологія',
        emoji: '🧠',
        field_id: 5,
      },
      {
        id: 54,
        name: 'Соціологія',
        emoji: '💬',
        field_id: 5,
      },
    ],
  },
  {
    id: 0o6,
    name: 'Журналістика',
    emoji: '📰',
    filters: [
      {
        id: 0o61,
        name: 'Журналістика',
        emoji: '📰',
        field_id: 6,
      },
    ],
  },
  {
    id: 7,
    name: 'Управління та адміністрування',
    emoji: '🏢',
    filters: [
      {
        id: 71,
        name: 'Облік і оподаткування',
        emoji: '🧾',
        field_id: 7,
      },
      {
        id: 72,
        name: 'Фінанси, банківська справа та страхування',
        emoji: '💰',
        field_id: 7,
      },
      {
        id: 73,
        name: 'Менеджмент',
        emoji: '💼',
        field_id: 7,
      },
      {
        id: 75,
        name: 'Маркетинг',
        emoji: '👨‍💼',
        field_id: 7,
      },
      {
        id: 76,
        name: 'Підприємництво, торгівля та біржова діяльність',
        emoji: '📈',
        field_id: 7,
      },
    ],
  },
  {
    id: 8,
    name: 'Право',
    emoji: '⚖️',
    filters: [
      {
        id: 81,
        name: 'Право',
        emoji: '⚖️',
        field_id: 8,
      },
    ],
  },
  {
    id: 9,
    name: 'Біологія',
    emoji: '🧬',
    filters: [
      {
        id: 91,
        name: 'Біологія',
        emoji: '🧬',
        field_id: 9,
      },
    ],
  },
  {
    id: 10,
    name: 'Природничі науки',
    emoji: '🏞️',
    filters: [
      {
        id: 101,
        name: 'Екологія',
        emoji: '🌿',
        field_id: 10,
      },
      {
        id: 102,
        name: 'Хімія',
        emoji: '🧪',
        field_id: 10,
      },
      {
        id: 103,
        name: 'Науки про Землю',
        emoji: '🌎',
        field_id: 10,
      },
      {
        id: 104,
        name: 'Фізика та астрономія',
        emoji: '🔭',
        field_id: 10,
      },
      {
        id: 105,
        name: 'Прикладна фізика та наноматеріали',
        emoji: '🔬',
        field_id: 10,
      },
      {
        id: 106,
        name: 'Географія',
        emoji: '🗺️',
        field_id: 10,
      },
    ],
  },
  {
    id: 11,
    name: 'Математика та статистика',
    emoji: '➗',
    filters: [
      {
        id: 111,
        name: 'Математика',
        emoji: '➕',
        field_id: 11,
      },
      {
        id: 112,
        name: 'Статистика',
        emoji: '📊',
        field_id: 11,
      },
      {
        id: 113,
        name: 'Прикладна математика',
        emoji: '🧮',
        field_id: 11,
      },
    ],
  },
  {
    id: 12,
    name: 'Інформаційні технології',
    emoji: '💻',
    filters: [
      {
        id: 121,
        name: 'Інженерія програмного забезпечення',
        emoji: '💾',
        field_id: 12,
      },
      {
        id: 122,
        name: "Комп'ютерні науки",
        emoji: '💻',
        field_id: 12,
      },
      {
        id: 123,
        name: "Комп'ютерна інженерія",
        emoji: '🖥️',
        field_id: 12,
      },
      {
        id: 124,
        name: 'Системний аналіз',
        emoji: '🔎',
        field_id: 12,
      },
      {
        id: 125,
        name: 'Кібербезпека',
        emoji: '🔐',
        field_id: 12,
      },
      {
        id: 126,
        name: 'Інформаційні системи та технології',
        emoji: '📂',
        field_id: 12,
      },
    ],
  },
  {
    id: 13,
    name: 'Механічна інженерія',
    emoji: '🚂',
    filters: [
      {
        id: 131,
        name: 'Прикладна механіка',
        emoji: '🧑‍🔧',
        field_id: 13,
      },
      {
        id: 132,
        name: 'Матеріалознавство',
        emoji: '🧱',
        field_id: 13,
      },
      {
        id: 133,
        name: 'Галузеве машинобудування',
        emoji: '🛠',
        field_id: 13,
      },
      {
        id: 134,
        name: 'Авіаційна та ракетно-космічна техніка',
        emoji: '🚀',
        field_id: 13,
      },
      {
        id: 135,
        name: 'Суднобудування',
        emoji: '🚢',
        field_id: 13,
      },
      {
        id: 136,
        name: 'Металургія',
        emoji: '🪙',
        field_id: 13,
      },
    ],
  },
  {
    id: 14,
    name: 'Електрична інженерія',
    emoji: '🔌',
    filters: [
      {
        id: 141,
        name: 'Електроенергетика, електротехніка та електромеханіка',
        emoji: '🔌',
        field_id: 14,
      },
      {
        id: 142,
        name: 'Енергетичне машинобудування',
        emoji: '⚡',
        field_id: 14,
      },
      {
        id: 143,
        name: 'Атомна енергетика',
        emoji: '⚛️',
        field_id: 14,
      },
      {
        id: 144,
        name: 'Теплоенергетика',
        emoji: '🔥',
        field_id: 14,
      },
      {
        id: 145,
        name: 'Гідроенергетика',
        emoji: '🌊',
        field_id: 14,
      },
    ],
  },
  {
    id: 15,
    name: 'Автоматизація та приладобудування',
    emoji: '🤖',
    filters: [
      {
        id: 151,
        name: 'Автоматизація та комп’ютерно-інтегровані технології',
        emoji: '🤖',
        field_id: 15,
      },
      {
        id: 152,
        name: 'Метрологія та інформаційно-вимірювальна техніка',
        emoji: '📏',
        field_id: 15,
      },
      {
        id: 153,
        name: 'Мікро- та наносистемна техніка',
        emoji: '🔬',
        field_id: 15,
      },
    ],
  },
  {
    id: 16,
    name: 'Хімічна та біоінженерія',
    emoji: '🧪',
    filters: [
      {
        id: 161,
        name: 'Хімічні технології та інженерія',
        emoji: '🧪',
        field_id: 16,
      },
      {
        id: 162,
        name: 'Біотехнології та біоінженерія',
        emoji: '🧬',
        field_id: 16,
      },
      {
        id: 163,
        name: 'Біомедична інженерія',
        emoji: '💉',
        field_id: 16,
      },
    ],
  },
  {
    id: 17,
    name: 'Електроніка та телекомунікації',
    emoji: '💡',
    filters: [
      {
        id: 171,
        name: 'Електроніка',
        emoji: '🔋',
        field_id: 17,
      },
      {
        id: 172,
        name: 'Телекомунікації та радіотехніка',
        emoji: '☎️',
        field_id: 17,
      },
      {
        id: 173,
        name: 'Авіоніка',
        emoji: '🛰️',
        field_id: 17,
      },
    ],
  },
  {
    id: 18,
    name: 'Виробництво та технології',
    emoji: '🏭',
    filters: [
      {
        id: 181,
        name: 'Харчові технології',
        emoji: '🍞',
        field_id: 18,
      },
      {
        id: 182,
        name: 'Технології легкої промисловості',
        emoji: '👕',
        field_id: 18,
      },
      {
        id: 183,
        name: 'Технології захисту навколишнього середовища',
        emoji: '🌿 ',
        field_id: 18,
      },
      {
        id: 184,
        name: 'Гірництво',
        emoji: '⛏',
        field_id: 18,
      },
      {
        id: 185,
        name: 'Нафтогазова інженерія та технології',
        emoji: '🛢️',
        field_id: 18,
      },
      {
        id: 186,
        name: 'Видавництво та поліграфія',
        emoji: '📚',
        field_id: 18,
      },
      {
        id: 187,
        name: 'Деревообробні та меблеві технології',
        emoji: '🪑',
        field_id: 18,
      },
    ],
  },
  {
    id: 19,
    name: 'Архітектура та будівництво',
    emoji: '🏗️',
    filters: [
      {
        id: 191,
        name: 'Архітектура та містобудування',
        emoji: '🏬',
        field_id: 19,
      },
      {
        id: 192,
        name: 'Будівництво та цивільна інженерія',
        emoji: '🏠',
        field_id: 19,
      },
      {
        id: 193,
        name: 'Геодезія та землеустрій',
        emoji: '🌍',
        field_id: 19,
      },
      {
        id: 194,
        name: 'Гідротехнічне будівництво, водна інженерія та водні технології',
        emoji: '🌊',
        field_id: 19,
      },
    ],
  },
  {
    id: 20,
    name: 'Аграрні науки та продовольство',
    emoji: '👨‍🌾',
    filters: [
      {
        id: 201,
        name: 'Агрономія',
        emoji: '👨‍🌾',
        field_id: 20,
      },
      {
        id: 202,
        name: 'Захист і карантин рослин',
        emoji: '🌱',
        field_id: 20,
      },
      {
        id: 203,
        name: 'Садівництво та виноградарство',
        emoji: '🍇',
        field_id: 20,
      },
      {
        id: 204,
        name: 'Технологія виробництва і переробки продукції тваринництва',
        emoji: '🐄',
        field_id: 20,
      },
      {
        id: 205,
        name: 'Лісове господарство',
        emoji: '🌲',
        field_id: 20,
      },
      {
        id: 206,
        name: 'Садово-паркове господарство',
        emoji: '🌳',
        field_id: 20,
      },
      {
        id: 207,
        name: 'Водні біоресурси та аквакультура',
        emoji: '🌊',
        field_id: 20,
      },
      {
        id: 208,
        name: 'Агроінженерія',
        emoji: '🚜',
        field_id: 20,
      },
    ],
  },
  {
    id: 21,
    name: 'Ветеринарна медицина',
    emoji: '🐕',
    filters: [
      {
        id: 211,
        name: 'Ветеринарна медицина',
        emoji: '👩‍⚕️',
        field_id: 21,
      },
      {
        id: 212,
        name: 'Ветеринарна гігієна, санітарія і експертиза',
        emoji: '🐾',
        field_id: 21,
      },
    ],
  },
  {
    id: 22,
    name: "Охорона здоров'я",
    emoji: '❤️',
    filters: [
      {
        id: 221,
        name: 'Стоматологія',
        emoji: '🦷',
        field_id: 22,
      },
      {
        id: 222,
        name: 'Медицина',
        emoji: '🏥',
        field_id: 22,
      },
      {
        id: 223,
        name: 'Медсестринство',
        emoji: '👩🏻‍⚕️',
        field_id: 22,
      },
      {
        id: 224,
        name: 'Технології медичної діагностики та лікування',
        emoji: '🩺',
        field_id: 22,
      },
      {
        id: 225,
        name: 'Медична психологія',
        emoji: '🧠',
        field_id: 22,
      },
      {
        id: 226,
        name: 'Фармація, промислова фармація',
        emoji: '💊',
        field_id: 22,
      },
      {
        id: 227,
        name: 'Фізична терапія, ерготерапія',
        emoji: '💆',
        field_id: 22,
      },
      {
        id: 228,
        name: 'Педіатрія',
        emoji: '👩‍🍼',
        field_id: 22,
      },
      {
        id: 229,
        name: 'Громадське здоров`я',
        emoji: '👨‍👩‍👦‍👦',
        field_id: 22,
      },
    ],
  },
  {
    id: 23,
    name: 'Соціальна робота',
    emoji: '💁',
    filters: [
      {
        id: 231,
        name: 'Соціальна робота',
        emoji: '🙋',
        field_id: 23,
      },
      {
        id: 232,
        name: 'Соціальне забезпечення',
        emoji: '🤕',
        field_id: 23,
      },
    ],
  },
  {
    id: 24,
    name: 'Сфера обслуговування',
    emoji: '🌇',
    filters: [
      {
        id: 241,
        name: 'Готельно-ресторанна справа',
        emoji: '🏨',
        field_id: 24,
      },
      {
        id: 242,
        name: 'Туризм',
        emoji: '🧳',
        field_id: 24,
      },
    ],
  },
  {
    id: 25,
    name: 'Воєнні науки, національна безпека, безпека державного кордону',
    emoji: '🪖',
    filters: [
      {
        id: 251,
        name: 'Державна безпека',
        emoji: '🪖',
        field_id: 25,
      },
      {
        id: 256,
        name: 'Національна безпека',
        emoji: '🪖',
        field_id: 25,
      },
    ],
  },
  {
    id: 26,
    name: 'Цивільна безпека',
    emoji: '👨‍💼',
    filters: [
      {
        id: 261,
        name: 'Пожежна безпека',
        emoji: '🧑‍🚒',
        field_id: 26,
      },
      {
        id: 262,
        name: 'Правоохоронна діяльність',
        emoji: '👮',
        field_id: 26,
      },
      {
        id: 263,
        name: 'Цивільна безпека',
        emoji: '👨‍💼',
        field_id: 26,
      },
    ],
  },
  {
    id: 27,
    name: 'Транспорт',
    emoji: '🚚',
    filters: [
      {
        id: 271,
        name: 'Річковий та морський транспорт',
        emoji: '⛵',
        field_id: 27,
      },
      {
        id: 272,
        name: 'Авіаційний транспорт',
        emoji: '🛩️',
        field_id: 27,
      },
      {
        id: 273,
        name: 'Залізничний транспорт',
        emoji: '🚆',
        field_id: 27,
      },
      {
        id: 274,
        name: 'Автомобільний транспорт',
        emoji: '🚗',
        field_id: 27,
      },
      {
        id: 275,
        name: 'Транспортні технології',
        emoji: '🚐',
        field_id: 27,
      },
    ],
  },
  {
    id: 28,
    name: 'Публічне управління та адміністрування',
    emoji: '📢',
    filters: [
      {
        id: 281,
        name: 'Публічне управління та адміністрування',
        emoji: '📢',
        field_id: 28,
      },
    ],
  },
  {
    id: 29,
    name: 'Міжнародні відносини',
    emoji: '🌍',
    filters: [
      {
        id: 291,
        name: 'Міжнародні відносини, суспільні комунікації та регіональні студії',
        emoji: '🌐',
        field_id: 29,
      },
      {
        id: 292,
        name: 'Міжнародні економічні відносини',
        emoji: '📈',
        field_id: 29,
      },
      {
        id: 293,
        name: 'Міжнародне право',
        emoji: '⚖️',
        field_id: 29,
      },
    ],
  },
];
